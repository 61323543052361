import {get} from 'axios'
import {debounce} from 'throttle-debounce'
import UniqBy from 'lodash.uniqby'

export default debounce(1000, false, (mode = 'get', $state) ->
  @loading = true
  {
    categories
    brands
    keywords
    subcategories
    price_from
    price_to
    discounts
  } = @search_filters
  q = {
    items_product_with_discount: discounts if discounts
    item_subcategory_id_in: subcategories if subcategories.length
    item_category_id_in: categories if categories.length
    item_brand_id_in: brands if brands.length
    item_title_i_cont_all: keywords if keywords
    item_public_price_published_price_gteq: price_from if price_from
    item_public_price_published_price_lteq: price_to if price_to
  }
  request = get('/products/search.json', {params: {id: @id, q, @page, sort: [@sort]}})
  page = 0
  if mode == 'infinite'
    request.then ({data}) =>
      @loading = false
      quantity = data.products.length
      new_products = @products
      new_products.push(data.products...)
      @products = UniqBy(new_products, 'id')
      if (quantity < 15)
        $state.complete()
        @page = 0
      else
        @page++
        $state.loaded()
  if mode == 'get'
    @products = []
    request.then ({data}) =>
      @page++
      @products = data.products
      @loading = false
)
